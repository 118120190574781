<template>
  <section class="wrap">
    <div class="content">
      <div class="top">
        <div class="order">
          {{ $t('package.commodity_order') }}：<span>{{ payData.orderId }}</span>
          <!-- 商品订单 -->
        </div>
        <div class="price">
          {{ $t('package.payment_amount') }}：<span>{{ $t('currency_symbol') }}{{ price }}</span>
          <!-- 支付金额： -->
        </div>
      </div>
      <div class="bottom">
        <div class="time">
          {{ $t('package.WeChat_payment') }}&nbsp;&nbsp;
          <!-- 微信支付 -->
          <span>
            {{ $t('package.qr_expires_tip', 1) }}
            <i>{{ seconds }}</i>
            {{ $t('package.qr_expires_tip', 2) }}
          </span>
          <!-- 距离二维码过期还剩 | 秒，过期后请刷新页面重新获取二维码 -->
        </div>
        <div class="pics">
          <div class="qr">
            <qrcode-vue :value="payData.codeUrl" :size="196" level="H" />
            <div class="tips">{{ $t('package.open_phone_wechat_pay') }}</div>
            <!-- 请打开手机微信，扫一扫完成支付 -->
          </div>
          <div class="right">
            <img src="@/assets/image/wx_pay.png" alt="pay" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, getCurrentInstance, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import QrcodeVue from "qrcode.vue";
import { packagePayQr, packageOrderInfo } from "@/api/package";
export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const taskId = Number(route.query.taskId);
    const buyType = Number(route.query.buyType);
    const minPays = Number(route.query.minPays);
    const price = Number(route.query.price);

    const payData = ref({});
    packagePayQr({
      buyType,
      taskId,
      minPays,
      price,
    }).then((res) => {
      payData.value = res.data;
    });

    const seconds = ref(120);
    let timer = setInterval(() => {
      if (seconds.value > 0) {
        seconds.value--;
        if (payData.value.orderId && seconds.value % 2 === 0) orderVerify();
      } else {
        clearInterval(timer);
        proxy.$message.warning($t('package.qr_has_expired'));
        // 二维码已过期，请刷新页面重新获取二维码！
      }
    }, 1000);

    onUnmounted(() => {
      clearInterval(timer);
    });

    const orderVerify = () => {
      packageOrderInfo({
        orderId: payData.value.orderId,
      }).then((res) => {
        if (res.data.status === 1) {
          proxy.$message.success($t('package.pay_success'));
          // 支付成功
          router.replace({
            path: "/package/introduce",
            query: {
              id: taskId,
            },
          });
        }
      });
    };

    return {
      price,
      payData,
      seconds,
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 40px 0;
  .content {
    .mixinWrap(1080px);
    background-color: #fff;
    padding: 60px 260px;
    .top {
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 10px;
      margin-bottom: 10px;
      .order {
        font-size: 14px;
        margin-bottom: 5px;
        span {
          color: #666;
        }
      }
      .price {
        font-size: 14px;
        margin-bottom: 5px;
        span {
          font-size: 25px;
          color: #e63450;
          // &::before {
          //   content: "¥";
          //   font-size: 18px;
          // }
        }
      }
    }
    .bottom {
      .time {
        font-size: 14px;
        padding: 20px 0;
        span {
          color: #666;
        }
        i {
          color: #e63450;
        }
      }
      .pics {
        width: 442px;
        .mixinFlex(space-between);
        .qr {
          width: 196px;
          padding-top: 10px;
          .tips {
            height: 30px;
            color: #fff;
            background-color: #39c800;
            font-size: 12px;
            text-align: center;
            line-height: 30px;
            margin-top: 8px;
          }
        }
        .right {
          .mixinImgWrap(206px; 255px);
        }
      }
    }
  }
}
</style>